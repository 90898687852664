@font-face {
    font-family: 'Augusta-Regular';
    font-weight:400;
    font-style:normal;
    src: url('./fonts/Augusta-Regular.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Augusta-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Augusta-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/Augusta-Regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('./fonts/Augusta-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Augusta-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

p {
    white-space: pre-wrap;
}
